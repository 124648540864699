<!-- Navigation -->
<treo-vertical-navigation [appearance]="'thin'"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.default"
                          [opened]="!isScreenSmall">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <img [src]="_storageUtilsService.getLogo()">
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <ng-container *ngIf="(_ticketsService.showDrawToggle$ | async) && (_ticketsService.drawMode$ | async) === 'over'">
            <button mat-icon-button (click)="_ticketsService.changeDrawWrapper()"
                    class="mat-button-base xs:d-none">
                            <span class="mat-button-wrapper">
                                <mat-icon [svgIcon]="(_ticketsService.showDrawPanel$ | async) ? 'format_indent_decrease' : 'format_indent_increase'"></mat-icon>
                            </span>
            </button>
        </ng-container>

        <!-- Spacer -->
        <div class="spacer"></div>

        <div>
            <button class="icon-button"
                    mat-icon-button>
                        <span class="avatar">
                             <a href="https://support.dluckyslots.com/" target="_blank">
                                 <mat-icon class="cursor-pointer m-1" svgIcon="mat_outline:contact_support"></mat-icon>
                             </a>
                        </span>
            </button>
        </div>

        <!-- User -->
        <user></user>

    </div>

    <!-- Content -->
    <div class="content">
        <mat-progress-bar [mode]="'indeterminate'" value="40" *ngIf="loading"></mat-progress-bar>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer">
        {{currentYear}} &copy; {{currentSiteName}} 
    </div>

</div>
