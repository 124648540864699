import {Route} from '@angular/router';
import {InitialDataResolver} from 'app/app.resolvers';
import {LayoutComponent} from './layout/layout.component';
import {ConfigResolvers} from './core/utils/config.resolvers';

export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    // {path: '', pathMatch: 'full', redirectTo: 'public/dashboard'},

    // Auth routes (guest)
    {
        path: 'auth',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        resolve: {
            config: ConfigResolvers,
        },
        children: [
            {
                path: '',
                redirectTo: 'sign-in',
                pathMatch: 'full'
            },
            {
                path: 'verify/email/:hash',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'password/recovery/:hash',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            }
        ]
    },

    // Admin routes
    {
        path: 'control',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
            config: ConfigResolvers,
        },
        data: {
            layout: 'thin'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/control/control.module').then(m => m.ControlModule)
            }
        ]
    },
    {
        path: 'backend',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
            config: ConfigResolvers,
        },
        data: {
            layout: 'thin'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/backend/backend.module').then(m => m.BackendModule)
            }
        ]
    },
    {
        path: 'agent',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
            config: ConfigResolvers,
        },
        data: {
            layout: 'thin'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/agent/agent.module').then(m => m.AgentModule)
            }
        ]
    },

    {
        path: 'customer',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
            config: ConfigResolvers,
        },
        data: {
            layout: 'centered'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/customer/customer.module').then(m => m.CustomerModule)
            }
        ]
    },

    {
        path: '',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
            config: ConfigResolvers,
        },
        data: {
            layout: 'centered'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/public/public.module').then(m => m.PublicModule)
            },
            // 404 & Catch all
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () => import('app/modules/errors/errors.module').then(m => m.ErrorsModule)
            },
            // {path: '**', redirectTo: '404-not-found'}
        ]
    },

    // 404 & Catch all
    {
        path: '404-not-found',
        pathMatch: 'full',
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('app/modules/errors/errors.module').then(m => m.ErrorsModule)
    },
    {path: '**', redirectTo: '404-not-found'},
];
