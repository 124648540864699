<!-- Button -->
<ng-container *ngIf="user && user.id && authenticated; else singInButton">
    <button class="icon-button"
            mat-icon-button
            [matMenuTriggerFor]="userActions">
    <span class="avatar">
        <img *ngIf="showAvatar && user.avatar"
             [src]="user.avatar">
        <mat-icon *ngIf="!showAvatar || !user.avatar"
                  [svgIcon]="'account_circle'"></mat-icon>
    </span>
    </button>
    <mat-menu class="user-actions-menu"
              [xPosition]="'before'"
              #userActions="matMenu">
        <button mat-menu-item>
        <span class="user-info">
            <span>Signed in as</span>
            <span class="email">{{user.email}}</span>
        </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item
                (click)="signOut()">
            <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
            <span>Sign out</span>
        </button>
    </mat-menu>
</ng-container>
<ng-template #singInButton>
    <a class="link" [href]="'auth/sign-in'">
        <span>Sign in</span>
    </a>
</ng-template>
