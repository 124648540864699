import {
    controlNavigation,
    backendNavigation,
    horizontalNavigation,
    publicNavigation,
    agentNavigation, customerNavigation
} from './core/config/app.config';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {StorageUtilsService} from './core/utils/storage.utils.service';
import {COMMON} from './shared/contstants/common.const';

import {UserService} from './layout/common/user/user.service';
import {AuthService} from './core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {

    /**
     * Constructor
     *
     * @param _router
     * @param _userService
     * @param _authService
     * @param _storageUtilsService
     */
    constructor(
        private _router: Router,
        private _userService: UserService,
        private _authService: AuthService,
        private _storageUtilsService: StorageUtilsService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load user
     *
     * @private
     */
    private _loadUser(): Observable<any> {
        const isUser = this._userService.user && this._userService.user.id;
        if (localStorage.getItem(environment.authTokenKey) && !isUser) {
            return this._userService.loadUser();
        } else {
            return of(false);
        }
    }

    private _getSection(): Observable<any> {
        return of(this._storageUtilsService.getSection());
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin([
            // User
            this._getSection(),
            this._loadUser(),
        ]).pipe(
            map((data) => {
                const section = data[0];
                const user = data[1];
                this.checkSection(section, user);
                const navigation = this.getNavigation(data[0]);
                return {
                    navigation: navigation
                };
            })
        );
    }

    checkSection(section, user): void {
        if ((section === 'public' || !section) && user && user.id) {
            this._storageUtilsService.setSection(COMMON.SITE_SECTION_CUSTOMER);
        }
    }

    getNavigation(section: string): any {
        switch (section) {
            case COMMON.SITE_SECTION_CONTROL: {
                return {
                    default: controlNavigation,
                    horizontal: publicNavigation
                };
            }
            case COMMON.SITE_SECTION_BACKEND: {
                return {
                    default: backendNavigation,
                    horizontal: publicNavigation
                };
            }
            case COMMON.SITE_SECTION_PUBLIC: {
                return {
                    default: publicNavigation,
                    horizontal: publicNavigation
                };
            }
            case COMMON.SITE_SECTION_AGENT: {
                return {
                    default: agentNavigation,
                    horizontal: publicNavigation
                };
            }
            case COMMON.SITE_SECTION_CUSTOMER: {
                return {
                    default: customerNavigation,
                    horizontal: customerNavigation
                };
            }
            default: {
                return {
                    default: publicNavigation,
                    horizontal: publicNavigation
                };
            }
        }
    }
}
