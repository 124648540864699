<div class="treo-horizontal-navigation-wrapper">

    <ng-container *ngFor="let item of navigation">

        <!-- Skip the hidden items -->
        <ng-container>

            <!--            <ng-container *ngIf="isHasToken; else noToken">-->
            <ng-container *ngIf="!item.meta">
                <ng-template [ngxPermissionsOnly]="item.permission">

                    <!-- Basic -->
                    <treo-horizontal-navigation-basic-item class="treo-horizontal-navigation-menu-item"
                                                           *ngIf="item.type === 'basic'"
                                                           [item]="item"
                                                           [name]="name"></treo-horizontal-navigation-basic-item>

                    <!--            &lt;!&ndash; Branch: aside, collapsable, group &ndash;&gt;-->
                    <treo-horizontal-navigation-branch-item class="treo-horizontal-navigation-menu-item"
                                                            *ngIf="item.type === 'aside' || item.type === 'collapsable' || item.type === 'group'"
                                                            [item]="item"
                                                            [name]="name"></treo-horizontal-navigation-branch-item>

                    <!--            &lt;!&ndash; Spacer &ndash;&gt;-->
                    <!--            <treo-horizontal-navigation-spacer-item class="treo-horizontal-navigation-menu-item"-->
                    <!--                                                   *ngIf="item.type === 'spacer'"-->
                    <!--                                                   [item]="item"-->
                    <!--                                                   [name]="name"></treo-horizontal-navigation-spacer-item>-->
                </ng-template>
            </ng-container>
            <!--            </ng-container>-->
            <!--            <ng-template #noToken>-->
            <ng-container *ngIf="item.meta && item.meta.notLogin">
                <!-- Basic -->
                <treo-horizontal-navigation-basic-item class="treo-horizontal-navigation-menu-item"
                                                       *ngIf="item.type === 'basic'"
                                                       [item]="item"
                                                       [name]="name"></treo-horizontal-navigation-basic-item>

                <!--            &lt;!&ndash; Branch: aside, collapsable, group &ndash;&gt;-->
                <treo-horizontal-navigation-branch-item class="treo-horizontal-navigation-menu-item"
                                                        *ngIf="item.type === 'aside' || item.type === 'collapsable' || item.type === 'group'"
                                                        [item]="item"
                                                        [name]="name"></treo-horizontal-navigation-branch-item>
            </ng-container>
            <!--            </ng-template>-->
        </ng-container>

    </ng-container>

</div>
