// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    clientId: 'ac0b3296-016d-40bb-bab8-df9efd51da23',
    clientSecret: 'j3IAxjeGDu7rCZSRy4pYLvFqDBnEXCbZRHYsZEw2',
    apiUrl: 'https://getassistance.payments.dev.zenzenzen.net/api',
    apiUrlSection: 'https://getassistance.payments.dev.zenzenzen.net/api/',
    authTokenKey: 'access_token',
    currentSiteName: 'GetAssistance',
    rollbarId: '81b372fb67b04079b57d879c4e6b60cc', // От слиент ид
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
