import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {Ticket} from '../types/tickets.types';

import {StorageUtilsService} from '../../../core/utils/storage.utils.service';
import {TreoMediaWatcherService} from '../../../../@treo/services/media-watcher';

@Injectable({
    providedIn: 'root'
})
export class TicketsService {
    // Private
    private _tickets: BehaviorSubject<Ticket[] | null>;
    private _logs: BehaviorSubject<Ticket[] | null>;
    private _userSite: BehaviorSubject<Ticket[] | null>;

    private _pagination: BehaviorSubject<number | null>;
    private _paginationLogs: BehaviorSubject<number | null>;
    private _showDrawToggle: BehaviorSubject<boolean | null>;
    private _showDrawPanel: BehaviorSubject<boolean | null>;


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param _storageUtilsService
     * @param _treoMediaWatcherService
     */
    constructor(
        private _httpClient: HttpClient,
        private _storageUtilsService: StorageUtilsService,
        private _treoMediaWatcherService: TreoMediaWatcherService,
    ) {
        // Set the private defaults
        this._tickets = new BehaviorSubject([]);
        this._logs = new BehaviorSubject([]);
        this._userSite = new BehaviorSubject(null);

        this._pagination = new BehaviorSubject(null);
        this._paginationLogs = new BehaviorSubject(null);
        this._showDrawToggle = new BehaviorSubject(false);
        this._showDrawPanel = new BehaviorSubject(false);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for ticket
     */
    get tickets$(): Observable<Ticket[]> {
        return this._tickets.asObservable();
    }

    get tickets(): Ticket[] {
        return this._tickets.getValue();
    }

    get userSite(): any {
        return this._userSite.getValue();
    }

    get logs$(): Observable<any[]> {
        return this._logs.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<number> {
        return this._pagination.asObservable();
    }

    get paginationLogs$(): Observable<number> {
        return this._paginationLogs.asObservable();
    }

    get drawMode$(): any {
        return this._treoMediaWatcherService.onMediaChange$.pipe(
            map(({matchingAliases}) => {
                // Set the drawerMode and drawerOpened if the given breakpoint is active
                if (matchingAliases.includes('xs')) {
                    return 'over';
                } else {
                    return 'side';
                }
            })
        );
    }

    get showDrawPanel$(): any {
        return this._showDrawPanel.asObservable();
    }

    get showDrawToggle$(): any {
        return this._showDrawToggle.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    changeDrawWrapper(value?: boolean): void {
        if (value !== undefined) {
            return this._showDrawPanel.next(value);
        }
        this._showDrawPanel.next(!this._showDrawPanel.getValue());
    }

    changeDrawToggle(value: boolean): void {
        this._showDrawToggle.next(value);
    }

    resetTickets(): void {
        this._tickets.next([]);
    }

    getUrl(id?: string | number, addToUrl?: string | number): string {
        let url = `${this._storageUtilsService.urlDependingSection(3)}/tickets`;
        if (id) {
            url = url + `/${id}`;
        }
        if (!!addToUrl) {
            url = url + `/${addToUrl}`;
        }
        return url;
    }

    /**
     * Get ticket
     */
    getTickets(params?: any, toMe: boolean = false): Observable<any> {
        const url = toMe ? this.getUrl('participates') : this.getUrl();
        return this._httpClient.get<any>(url, {
            params: params
        }).pipe(
            tap((res) => {
                this._tickets.next(res.data.data);
                this._pagination.next(res.data.total);
            })
        );
    }

    getTicketNotes(id: number, params: any): Observable<any> {
        return this._httpClient.get<any>(this.getUrl(id, 'notes'), {params: params}).pipe(
            map((res) => res.data)
        );
    }

    getTicketLogs(id: number, params: any): Observable<any> {
        return this._httpClient.get<any>(this.getUrl(id, 'logs'), {params: params}).pipe(
            tap((res) => {
                this._logs.next(res.data.data);
                this._paginationLogs.next(res.data.total);
            })
        );
    }

    getTicketMessages(id: number, params: any): Observable<any> {
        return this._httpClient.get<any>(this.getUrl(id, 'messages'), {params: params}).pipe(
            map((res) => res.data)
        );
    }

    getUserSite(): Observable<any> {
        return this._httpClient.get<any>(`${this._storageUtilsService.urlDependingSection(3)}/me`).pipe(
            tap(res => {
                this._userSite.next(res.data);
            }),
            map((res) => res.data)
        );
    }

    updateUserSite(data): any {
        return this._httpClient.patch<any>(`${this._storageUtilsService.urlDependingSection(3)}/me`, data);
    }

    getTicketById(id: number, params: any): Observable<Ticket> {
        return this._httpClient.get<any>(this.getUrl(id), {
            params: params
        }).pipe(
            map((res) => res.data)
        );
    }

    getTicketReports(params: any): Observable<Ticket> {
        return this._httpClient.get<any>(`${this._storageUtilsService.urlDependingSection(3)}/reports/tickets`, {
            params: params
        }).pipe(
            map((res) => res.data)
        );
    }

    getReportsTopCategories(params: any): Observable<Ticket> {
        return this._httpClient.get<any>(`${this._storageUtilsService.urlDependingSection(3)}/reports/top_categories`, {
            params: params
        }).pipe(
            map((res) => res.data)
        );
    }

    getReportsTicketsSource(params: any): Observable<Ticket> {
        return this._httpClient.get<any>(`${this._storageUtilsService.urlDependingSection(3)}/reports/tickets_source`, {
            params: params
        }).pipe(
            map((res) => res.data)
        );
    }


    /**
     * Create empty Ticket
     */
    createEmptyTicket(): Observable<any> {
        return this.tickets$.pipe(
            take(1),
            map((tickets) => {
                const newTicket: Ticket = {
                    id: null,
                    isCreating: true
                };
                //
                // // Update the ticket with the new Ticket
                this._tickets.next([newTicket, ...tickets]);

                this.updatePagination(+1);

                // // Return the new Ticket
                return newTicket;
            })
        );
    }

    /**
     * Create Ticket
     */
    createTicket(ticket: Ticket): Observable<any> {
        return this.tickets$.pipe(
            take(1),
            switchMap(tickets => this._httpClient.post<any>(this.getUrl(), ticket).pipe(
                map((response) => {
                        const createdTicket = response.data;

                        // Find the index of the deleted ticket
                        const index = tickets.findIndex(item => item.isCreating);

                        // Update the empty ticket
                        tickets[index] = createdTicket;

                        // Update the ticket
                        this._tickets.next(tickets);

                        // Return the deleted status
                        return createdTicket;
                    },
                    error => {
                        return error;
                    })
            ))
        );
    }

    updateTicket(data: any, id): Observable<any> {
        return this.tickets$.pipe(
            take(1),
            switchMap(tickets => this._httpClient.patch<any>(this.getUrl(id), data).pipe(
                map((response) => {
                        const updatedTicket = response.data;

                        // Find the index of the deleted ticket
                        const index = tickets.findIndex(item => item.id === id);

                        // Update the empty ticket
                        tickets[index] = updatedTicket;

                        // Update the ticket
                        this._tickets.next(tickets);

                        // Return the deleted status
                        return updatedTicket;
                    },
                    error => {
                        return error;
                    })
            ))
        );
    }

    cancelCreate(): Observable<boolean> {
        return this.tickets$.pipe(
            take(1),
            map((tickets) => {

                // Find the index of the deleted ticket
                const index = tickets.findIndex(item => item.isCreating);

                // Delete the ticket
                tickets.splice(index, 1);

                // Update the ticket
                this._tickets.next(tickets);

                this.updatePagination(-1);
                // Return the deleted status
                return true;
            })
        );
    }

    updatePagination(val: number): void {
        this.pagination$.pipe(take(1)).subscribe(res => {
            const data = res + val;
            this._pagination.next(data);
        });
    }

    // mass actions
    /**
     * Update ticket
     *
     * @param data
     */
    updateMass(data): Observable<any> {
        return this.tickets$.pipe(
            take(1),
            switchMap(tickets => this._httpClient.patch<any>(this.getUrl('update_mass'), data).pipe(
                map((response) => {
                        const updatedTicket = response.data;
                        // Find the index of the updated ticket
                        data.ids.map(id => {

                            const index = tickets.findIndex(item => item.id === id);

                            if (data.data.category) {
                                updatedTicket.data.category = data.data.category;
                            }

                            // Update the ticket
                            tickets[index] = {...tickets[index], ...updatedTicket.data};

                        });
                        // Update the ticket
                        this._tickets.next(tickets);

                        // Return the updated ticket
                        return updatedTicket;
                    }
                )
            )),
            catchError(error => {
                return throwError(error);
            }),
        );
    }

    resolveMass(data, one = false): Observable<any> {
        const field = one ? data.id + '/resolve' : 'resolve_mass';
        return this.tickets$.pipe(
            take(1),
            switchMap(tickets => this._httpClient.post<any>(this.getUrl(field), data).pipe(
                map((response) => {
                        const updatedTicket = response.data;
                        // Find the index of the updated ticket
                        if (!one) {
                            data.ids.map(id => {
                                const index = tickets.findIndex(item => item.id === id);

                                // Update the ticket
                                tickets[index].status = 'resolved';

                                // Update the ticket
                                this._tickets.next(tickets);
                            });
                        }else{
                            const index = tickets.findIndex(item => item.id === data.id);

                            // Update the ticket
                            tickets[index].status = 'resolved';

                            // Update the ticket
                            this._tickets.next(tickets);
                        }

                        // Return the updated ticket
                        return updatedTicket;
                    }
                )
            )),
            catchError(error => {
                return throwError(error);
            }),
        );
    }

    /**
     * Delete the ticket
     *
     * @param data
     */
    deleteMass(data: { ids: number[] }): Observable<boolean> {
        return this.tickets$.pipe(
            take(1),
            switchMap(tickets => this._httpClient.request<Ticket>('delete', this.getUrl('delete_mass'), {
                body: data
            }).pipe(
                map((bol) => {

                    // Find the index of the deleted ticket
                    data.ids.map(id => {
                        const index = tickets.findIndex(item => item.id === id);

                        // Delete the ticket
                        tickets.splice(index, 1);
                    });

                    // Update the ticket
                    this._tickets.next(tickets);

                    this.updatePagination(data.ids.length);
                    // Return the deleted status
                    return true;
                })
            ))
        );
    }

    // details actions

    updateTags(id: number, data: { ids: number[] }): Observable<any> {
        return this._httpClient.post<any>(this.getUrl(id, 'tags/update'), data);
    }

    updateUsers(id: number, data: { ids: number[] }, isDetach = false): Observable<any> {
        const addToUrl = isDetach ? 'users/detach' : 'users/update';
        return this._httpClient.post<any>(this.getUrl(id, addToUrl), data);
    }

    updateLinkedTickets(id: number, data: { ticket_ids: number[] }, isDetach = false): Observable<any> {
        const addToUrl = isDetach ? 'links/detach' : 'links/update';
        return this._httpClient.post<any>(this.getUrl(id, addToUrl), data);
    }

    sendAnswer(id: number, data: any): Observable<any> {
        return this._httpClient.post<any>(this.getUrl(id, 'answer'), data);
    }

    sendCustomField(id: number, data: any): Observable<any> {
        return this._httpClient.post<any>(this.getUrl(id, 'fill_custom_fields'), data);
    }

    uploadFile(data: any): any {
        // const url = file && file.type === 'application/pdf' ? 'uploads/pdf' : 'uploads/images';
        return this._httpClient.post(environment.apiUrl + 'uploads/images', data);
    }

}
