import {Layout} from 'app/layout/layout.types';

import {PERMISSIONS} from '../../shared/contstants/permissions.const';

// Theme type
export type Theme = 'auto' | 'dark' | 'light';

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
    layout: Layout;
    theme: Theme;
}

/**
 * Default configuration for the entire application. This object is used by
 * 'ConfigService' to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * 'ConfigService'.
 */
export const appConfig: AppConfig = {
    layout: 'centered',
    theme: 'light'
};


export const controlNavigation: any[] = [
    // {
    //     id: 'dashboard',
    //     title: 'Dashboard',
    //     type: 'basic',
    //     icon: 'mat_outline:dashboard',
    //     link: '/control/dashboard',
    // },
    {
        id: 'roles',
        title: 'Roles',
        type: 'basic',
        icon: 'mat_outline:list',
        link: 'roles',
        permission: PERMISSIONS.ROLES.VIEW
    },
    {
        id: 'organizations',
        title: 'Organizations',
        type: 'basic',
        icon: 'mat_outline:list',
        link: 'organizations',
        permission: PERMISSIONS.ORGANIZATIONS.VIEW
    }

];
export const backendNavigation: any[] = [
    // {
    //     id: 'dashboard',
    //     title: 'Dashboard',
    //     type: 'basic',
    //     icon: 'mat_outline:dashboard',
    //     link: '/backend/dashboard',
    // },
    {
        id: 'organizations',
        title: 'Organizations',
        type: 'basic',
        icon: 'mat_outline:list',
        link: 'organizations',
        permission: PERMISSIONS.ORGANIZATIONS.VIEW
    },

];
export const publicNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/',
        exactMatch: true,
        meta: {
            notLogin: true,
        }
    },
    {
        id: 'faqs',
        title: 'FAQs',
        type: 'basic',
        icon: 'mat_outline:document-text',
        link: '/faqs',
        meta: {
            notLogin: true,
        }
    },
    {
        id: 'categories',
        title: 'Categories',
        type: 'basic',
        icon: 'mat_outline:book-open',
        link: '/categories',
        meta: {
            notLogin: true,
        }
    }
    // ,
    // {
    //     id: '94edf2d5-1bfb-4570-ba63-1307e353f5e9',
    //     title: 'ticket/94edf2d5-1bfb-4570-ba63-1307e353f5e9',
    //     type: 'basic',
    //     icon: 'mat_outline:dashboard',
    //     link: '/tickets/94edf2d5-1bfb-4570-ba63-1307e353f5e9',
    //     meta: {
    //         notLogin: true,
    //     }
    // }

];
export const agentNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: 'dashboard',
    },
    // {
    //     id: 'profile',
    //     title: 'Profile',
    //     type: 'basic',
    //     icon: 'mat_outline:account_circle',
    //     link: 'profile',
    // },

];
export const customerNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Opened Tickets',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/customer/tickets',
    },
    {
        id: 'dashboard',
        title: 'Profile Tickets',
        type: 'basic',
        icon: 'mat_outline:web',
        link: '/customer/profile/tickets',
    },
    {
        id: 'profile',
        title: 'Profile',
        type: 'basic',
        exactMatch: true,
        icon: 'mat_outline:account_circle',
        link: '/customer/profile',
    },
    {
        id: 'dashboard',
        title: 'Help center',
        type: 'basic',
        exactMatch: true,
        icon: 'mat_outline:dashboard',
        link: '/',
    },
    {
        id: 'faqs',
        title: 'FAQs',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/faqs',
    },
    {
        id: 'categories',
        title: 'Categories',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/categories',
    },

];
export const horizontalNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        exactMatch: true,
        link: '/',
        meta: {
            notLogin: true,
        }
    },
    {
        id: 'faqs',
        title: 'FAQs',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/faqs',
        meta: {
            notLogin: true,
        }
    },
    {
        id: 'categories',
        title: 'Categories',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/categories',
        meta: {
            notLogin: true,
        }
    }
    // ,
    // {
    //     id: '94edf2d5-1bfb-4570-ba63-1307e353f5e9',
    //     title: 'ticket/94edf2d5-1bfb-4570-ba63-1307e353f5e9',
    //     type: 'basic',
    //     icon: 'mat_outline:dashboard',
    //     link: '/tickets/94edf2d5-1bfb-4570-ba63-1307e353f5e9',
    //     meta: {
    //         notLogin: true,
    //     }
    // }
];
