export const COMMON: any = {
    CHANNEL_TYPES: ['mail', 'sms', 'telegram'],
    NOTIFICATIONS: ['waiting', 'processing', 'completed', 'cancelled'],
    NOTIFICATIONS_ITEMS: ['waiting', 'processing', 'completed', 'cancelled', 'read', 'delivered', 'pending'],
    PAYMENT_STATUSES: ['failed', 'refunded', 'paid', 'processing', 'unpaid', 'declined', 'forbidden', 'cancelled', 'dispute'],
    NOTIFICATIONS_FOR_USERS: ['pending', 'processing', 'delivered', 'failed', 'cancelled', 'read'],

    SITE_SECTION: ['backend', 'control', 'agent', 'customer', 'public'],
    SITE_SECTION_CONTROL: 'control',
    SITE_SECTION_BACKEND: 'backend',
    SITE_SECTION_AGENT: 'agent',
    SITE_SECTION_CUSTOMER: 'customer',
    SITE_SECTION_PUBLIC: 'public',
    COOKIE_KEY: ['GA_config'],

    CLASS_WIDTH: ['w-1', 'w-10', 'w-16', 'w-20', 'w-24', 'w-26', 'w-30', 'w-32', 'w-36', 'w-40', 'w-50', 'w-60', 'w-80', 'w-100', 'w-1/2', 'w-1/3', 'w-2/3', 'w-1/4', 'w-full'],

    LOGO_PATCH: 'assets/images/logo/logo.png',

    PRIORITIES: [{id: 0, name: 'low'}, {id: 1, name: 'medium'}, {id: 2, name: 'high'}],

    TABLE: {
        PARAMS: {
            'id[orderBy]': 'desc'
        },
        PAGINATOR: {
            PAGE_SIZE: 15,
            PAGE_OPTIONS: [5, 15, 50, 100, 200, 500]
        },
        SORT: {
            DIRECTIONS: [
                {
                    id: 'asc',
                    name: 'Ascending'
                },
                {
                    id: 'desc',
                    name: 'Descending'
                }
            ]
        }

    }
};
