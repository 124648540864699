<ng-container *ngIf="message">

    <div class="thread my-4" [ngClass]="{'right': message.type === 'customer'}">

        <!-- Thread header -->
        <div class="header p-4 xs:flex-wrap">

            <!-- Sender avatar -->
            <div class="avatar">
                <img ngxGravatar [email]="message.user && message.user.email || 'bot@getassistance.net'"/>
            </div>

            <!-- Info -->
            <div class="info">

                <!-- From -->

                <ng-container *ngIf="message.user; else bot">
                    <div class="from">{{message.user.first_name}} {{message.user.last_name}}</div>
                </ng-container>

                <ng-template #bot>
                    Chatbot
                </ng-template>

                <!-- To -->
                <div class="to" *ngIf="!isPublicSection">
                    <div class="others flex text-cool-gray-400">
                        <ng-container *ngIf="message.user">
                            <span class="email">{{message.user.email}}</span>
                        </ng-container>
                        <div class="bg-gray-400 h-1 m-2 rounded-full w-1"></div>
                        <span class="created">{{_tableUtilsService.getTimeAgo(message.created_at)}}</span>
                    </div>

                </div>

            </div>

        </div>

        <!-- Content -->
        <div class="content p-4"
             [innerHTML]="message.text">
        </div>

        <!-- Attachments -->
        <app-view-attachments class="p-4"
                [currentAttachments]="message.attachments"
                [forCustomField]="false" [showDelete]="false">
        </app-view-attachments>
    </div>
</ng-container>
