import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit, OnDestroy {

    show: string | boolean = null;

    _unsubscribeAll = new Subject();

    constructor(private location: Location) {
    }

    ngOnInit(): void {
        this.show = window.history.length && window.history.length > 1;

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    back(): void {
        this.location.back();
    }
}
