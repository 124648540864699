export const PERMISSIONS: any = {
    control: 'manage roles and permissions',
    backend: 'manage organizations',
    agent: 'view sites',
    customer: 'view sites',
    USERS: {
        VIEW: 'view users',
        MANAGE: 'manage users',
        VIEW_ROLES: 'view user roles'
    },
    ROLES: {
        VIEW: 'view roles and permissions',
        MANAGE: 'manage roles and permissions',
        VIEW_USERS: 'view role users',
        VIEW_PERMISSIONS: 'view roles and permissions',
    },
    SITES: {
        VIEW: 'view sites',
        MANAGE: 'manage sites',
    },
    TAGS: {
        VIEW: 'view tags',
        MANAGE: 'manage tags',
    },
    TICKETS: {
        VIEW: 'view tickets',
        MANAGE: 'manage tickets',
    },
    ARTICLES: {
        VIEW: 'view articles',
        MANAGE: 'manage articles',
    },
    AGENTS: {
        VIEW: 'view agents',
        MANAGE: 'manage agents',
    },
    ORGANIZATIONS: {
        VIEW: 'view organizations',
        MANAGE: 'manage organizations',
    },
    REPORTS: {
        VIEW: 'view reports',
        MANAGE: 'manage reports',
    },
    PACKAGES: {
        VIEW: 'view packages',
        MANAGE: 'manage packages',
    },
    METHODS: {
        VIEW: 'view methods',
        VIEW_RELATED: 'view related methods',
        MANAGE: 'manage methods',
    },
    CONDITIONS: {
        VIEW: 'view conditions',
        MANAGE: 'manage conditions',
    },
    FORMS: {
        VIEW: 'view forms',
        MANAGE: 'manage forms',
    },
    PAGES: {
        VIEW: 'view pages',
        MANAGE: 'manage pages',
    },
    PAYMENTS: {
        VIEW: 'view payments',
        MANAGE: 'manage payments',
    },
    CREDITCARDS: {
        VIEW: 'view creditcards',
        MANAGE: 'manage creditcards',
    },
    CONFIGS: {
        VIEW: 'view configs',
        MANAGE: 'manage configs',
    },
    CATEGORIES: {
        VIEW: 'view categories',
        MANAGE: 'manage categories',
    },
    TIPS: {
        VIEW: 'view tips',
        MANAGE: 'manage tips',
    },
    REVIEWS: {
        VIEW: 'view reviews',
        MANAGE: 'manage reviews',
    },
    NOTIFICATIONS: {
        VIEW: 'view notifications',
        MANAGE: 'manage notifications',
    },
    GRAND: 'grand',
    ME: 'me',
};
