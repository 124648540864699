import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {User} from 'app/layout/common/user/user.types';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    // Observables
    private _user: BehaviorSubject<User | null>;

    private url = environment.apiUrl;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this._user = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user(): User {
        return this._user.getValue();
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user data
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch(this.url + '/me', user);
    }

    updatePassword(value: any): Observable<any> {
        return this._httpClient.patch(this.url + '/me/password', value);
    }

    loadUser(): Observable<any> {

        const url = '/me';

        return this._httpClient.get(this.url + url).pipe(
            tap(res => {
                    this._user.next(res['data']);
                }
            ),
            map(res => res['data'])
        );
    }

    removeUser(): void {
        this._user.next(null);
    }
}
