import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {StorageUtilsService} from './storage.utils.service';

import {AuthService} from '../auth/auth.service';
import {Title} from '@angular/platform-browser';


@Injectable({
    providedIn: 'root'
})
export class ConfigResolvers implements Resolve<any> {

    /**
     * Constructor
     *
     * @param _router
     * @param _authService
     * @param _storageUtilsService
     * @param titleService
     */
    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _storageUtilsService: StorageUtilsService,
        private titleService: Title,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _getSiteConfig(): Observable<any> {
        if (!this._storageUtilsService.config) {
            return this._authService.getSiteConfig();
        }
        this._storageUtilsService.checkConfig();
        return of(this._storageUtilsService.config);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this._getSiteConfig().pipe(
            map((data) => {
                return {
                    config: data
                };
            })
        );
    }
}
