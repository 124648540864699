import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {TreoNavigationModule} from '@treo/components/navigation';
import {UserModule} from 'app/layout/common/user/user.module';
import {ThinLayoutComponent} from 'app/layout/layouts/vertical/thin/thin.component';

@NgModule({
    declarations: [
        ThinLayoutComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        TreoNavigationModule,
        UserModule,
        MatProgressBarModule
    ],
    exports: [
        ThinLayoutComponent
    ]

})
export class ThinLayoutModule {
}
